/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */
import { destinationDetail } from './destinationDetail-controller.ts';
import { DestinationDetailListController } from './destinationDetailList-controller';
var dependencies = ['comcast.common.authentication', 'comcast.common.communication', 'comcast.common.navigation', 'comcast.settings', 'ngMaterial', 'ngMessages'];
var defaultName = angular.module('comcast.settings.destinations', dependencies).component('destinationDetail', destinationDetail) // Someday this should be moved to a core module
.config(['$stateProvider', function ($stateProvider) {
  $stateProvider.state('settingDestinations', {
    // Specify params that this state can access from the URL
    url: '/destinations?destinationName&callLetter&marketId&destinationTypeId&{includeArchives:bool}&sortField&sortDirection',
    parent: 'settings',
    // Specify default values for state params
    params: {
      destinationName: null,
      callLetter: null,
      marketId: null,
      destinationTypeId: null,
      includeArchives: false,
      sortField: null,
      sortDirection: null
    },
    reloadOnSearch: true,
    views: {
      'settingsContent@settings': {
        template: '<ui-view name="destinationsContent"/>'
      },
      'settingsSearch@settings': {
        template: '<ui-view name="destinationsSearch"/>'
      },
      'destinationsContent@settingDestinations': {
        template: require('./destinations-template.html'),
        controller: 'SettingsDestinationsController',
        controllerAs: 'vm'
      },
      'destinationsSearch@settingDestinations': {
        template: require('./destinationsSearch-template.html'),
        controller: 'SettingsDestinationsSearchController',
        controllerAs: 'vm'
      }
    },
    access: {
      requiresLogin: true,
      requiredPermissions: ['User'],
      permissionCheckType: 'AtLeastOne'
    }
  }).state('destinationDetails', {
    url: '/:id',
    parent: 'settingDestinations',
    params: {
      destinationName: null,
      callLetter: null,
      marketId: null,
      destinationTypeId: null,
      includeArchives: false,
      sortField: null,
      sortDirection: null
    },
    views: {
      destinationsContent: {
        template: '<destination-detail></destination-detail>'
      },
      destinationsSearch: {
        template: require('./destinationDetailList-template.html'),
        controller: DestinationDetailListController,
        controllerAs: 'vm'
      }
    },
    access: {
      requiresLogin: true,
      requiredPermissions: ['User'],
      permissionCheckType: 'AtLeastOne'
    }
  });
}]).name;
export { defaultName as default, dependencies };