/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */

declare var angular: angular.IAngularStatic;

import { ConfigureChannelMappingDialogController } from '../defaultmediaprefersdetail/dialogs/configureChannelMappingDialog-controller';

export const destinationDetail = {
    selector: 'destinationDetail',
    bindings: {},
    scope: {},
    replace: true,
    template: require('./destinationDetail-template.html'),
    controller: class DestinationDetailController {
        public destination: any = {};
        public distributionTypes: any = [
            { label: 'Electronic', value: 'online' },
            { label: 'Tape', value: 'offline' },
            { label: 'Download Only', value: 'webDownload' },
            { label: 'Cloud', value: 'cloud' },
        ];
        public destinationTypes: any = [];
        public promoDestTypeIds: any = [];
        public digitalDestTypeIds: any = [];
        public operationsDestinations: any = [];
        public hasPermission: any;
        public callLetterValidator: any = /^[a-zA-Z0-9\-\)\(\_\~\+\.]+$/;
        public akaModified: any;
        public mediaPrefObj: any = {};
        public sessionData: any;
        public receiver: any = {};
        public radioDestTypeIds: any = [];
        public destinationCapabilities: any = {};
        public destinationStatuses: any = {};
        public originalDestination: any = {};
        public vastVersions: any = [
            {
                label: 'Not Enabled',
                value: null,
            },
            {
                label: '3.0',
                value: '3.0',
            },
        ];
        public spotMetadataTransformList: any;
        public fileNamePatterns: any;
        public xmlFilenamePatterns: any;
        public maxPeakPatterns: any;
        public targetLoudnessPatterns: any;
        public markets: any;
        public mediaFormats: any;
        public searchSdFormat: string = '';
        public searchSdProxyFormat: string = '';
        public searchHdFormat: string = '';
        public searchHdProxyFormat: string = '';

        static get $inject() {
            return [
                '$rootScope',
                '$scope',
                '$state',
                '$stateParams',
                '$timeout',
                '$mdDialog',
                '$q',
                'DestinationResourceFactory',
                'DestinationTypeResourceFactory',
                'MarketResourceFactory',
                'NotificationService',
                'loginService',
                'MediaFormatResourceFactory',
                'EnumService',
                'ReceiverResourceFactory',
                'VideoFormatProfileResource',
            ];
        }

        constructor(
            public $rootScope: any,
            public $scope: any,
            public $state: any,
            public $stateParams: any,
            public $timeout: any,
            public $mdDialog: any,
            public $q: any,
            public DestinationResourceFactory: any,
            public DestinationTypeResourceFactory: any,
            public MarketResourceFactory: any,
            public NotificationService: any,
            public loginService: any,
            public MediaFormatResourceFactory: any,
            public EnumService: any,
            public ReceiverResourceFactory: any,
            public VideoFormatProfileResource: any,
        ) {
            /* BINDABLE : INJECTS */
            Object.assign(this, {
                $rootScope,
                $scope,
                $state,
                $stateParams,
                $timeout,
                $mdDialog,
                $q,
                DestinationResourceFactory,
                DestinationTypeResourceFactory,
                MarketResourceFactory,
                NotificationService,
                loginService,
                MediaFormatResourceFactory,
                EnumService,
                ReceiverResourceFactory,
                VideoFormatProfileResource,
            });

            /* BINDABLE : DATA */
            this.destination = {};
            this.destinationTypes = [];
            this.promoDestTypeIds = [];
            this.digitalDestTypeIds = [];
            this.operationsDestinations = [];
            this.hasPermission = loginService.hasPermission;
            this.mediaPrefObj = {};
            this.sessionData = loginService.getSessionData();
            this.receiver = {};
            this.radioDestTypeIds = [];
            this.destinationCapabilities = {};
            this.destinationStatuses = {};
            this.originalDestination = {};

            this.vastVersions = [
                {
                    label: 'Not Enabled',
                    value: null,
                },
                {
                    label: '3.0',
                    value: '3.0',
                },
            ];
        }

        /* IMPLEMENTATION : BINDABLE */
        archiveDestination($event:any) {
            let vm = this;
            let confirmOrNot;

            if (!vm.destination.archivedFlag) {
                let confirm = this.$mdDialog
                    .confirm()
                    .title('Would you like to continue?')
                    .textContent(
                        "Archiving an item will remove it from the default view. It will not be deleted but will be excluded in searches unless you select 'Include archives'."
                    )
                    .ariaLabel(
                        "Archiving an item will remove it from the default view. It will not be deleted but will be excluded in searches unless you select 'Include archives'."
                    )
                    .targetEvent($event)
                    .ok('Continue')
                    .cancel('Cancel');

                confirmOrNot = this.$mdDialog.show(confirm);
            } else {
                confirmOrNot = this.$q.when(true);
            }

            confirmOrNot.then(
                function () {
                    vm.destination.archivedFlag = !vm.destination.archivedFlag;
                    vm.DestinationResourceFactory.save(
                        { id: vm.destination.id },
                        vm.destination,
                        function (destination:any) {
                            vm.$scope.destinationDetailForm.$setPristine();
                            vm.originalDestination = angular.copy(destination);
                            if (destination.data.archivedFlag) {
                                vm.NotificationService.showNotificationToast(
                                    'Successfully archived destination'
                                );
                            } else {
                                vm.NotificationService.showNotificationToast(
                                    'Successfully unarchived destination'
                                );
                            }
                        },
                        function failure(err:any) {
                            vm.NotificationService.showNotificationToast(
                                'Unable to archive/unarchive the destination with the given information',
                                err.data
                            );
                        }
                    );
                },
                function () {
                    // User cancelled the archive function
                }
            );
        }

        deleteDestination($event:any) {
            let vm = this;
            let confirmOrNot;

            let confirm = this.$mdDialog
                .confirm()
                .title('Delete Destination')
                .textContent('Are you sure you want to delete destination?')
                .ariaLabel('Are you sure you want to delete destination?')
                .targetEvent($event)
                .ok('DELETE')
                .cancel('Cancel');
            confirmOrNot = this.$mdDialog.show(confirm);
            confirmOrNot.then(
                function () {
                    vm.DestinationResourceFactory.delete(
                        { id: vm.destination.id },
                        {},
                        function () {
                            //individual delete destination succeeded
                            vm.NotificationService.showNotificationToast(
                                'Successfully deleted destination'
                            );
                            vm.$state.go('settingDestinations', {
                                inherit: true,
                            });
                        },
                        function (err:any) {
                            //individual delete destination failure
                            vm.NotificationService.showNotificationToast(
                                'Unable to delete the destination',
                                err.data
                            );
                        }
                    );
                },
                function () {
                    // User cancelled the delete destination function
                }
            );
        }

        openTabContent(tabName:any) {
            $('.tab-pane').removeClass('active');
            $('.tab-button').removeClass('active');
            $('#' + tabName + 'TabContent').addClass('active');
            $('#' + tabName + 'TabButton').addClass('active');
        }

        resetMediaPrefObj(changeFromUI:any) {
            var vm = this;
            if (vm.destination.mediaPrefsOverrideFlag && changeFromUI) {
                vm._setDefaultDestinationMediaPrefs();
            }
            vm.mediaPrefObj = vm.destination.mediaPrefsOverrideFlag
                ? vm.destination
                : vm.destination.ReceiverAccount;
        }
        vastDisabled() {
            var vm = this;
            if(vm.destination.mediaPrefsOverrideFlag && (vm.destination.youtubeFlag || vm.destination.flashtalkingFlag || vm.destination.innovidFlag)) {
                vm.mediaPrefObj.vastVersion = null; 
                return true;
            }  else return false;
        }
        showVastMessage() {
            var vm = this;
            if(vm.destination.youtubeFlag || vm.destination.flashtalkingFlag || vm.destination.innovidFlag) {
                return true;
            } else {
                return false;
            }
        }
        convertToInt(value:any) {
            return parseInt(value, 10);
        }

        maxPeakSort(value:any) {
            return parseInt(value.value, 10);
        }

        disableProxyFrame(frameType:any) {
            let vm = this;
            if (vm.destination.mediaPrefsOverrideFlag) {
                if (frameType == 'HD') {
                    if (vm.mediaPrefObj.hdProxyFormatId) {
                        return false;
                    } else {
                        return true;
                    }
                } else {
                    // eslint-disable-next-line
                    if (vm.mediaPrefObj.sdProxyFormatId) {
                        return false;
                    } else {
                        return true;
                    }
                }
            } else {
                return true;
            }
        }

        loadMediaFormats(searchName:string, highDefinitionFlag:boolean, proxyFlag:boolean, isDigital:boolean) {
            let vm = this;

            return new Promise(async (resolve, reject) => {
                let mediaFormats = await vm.MediaFormatResourceFactory.getAll({
                    highDefinitionFlag,
                    proxyFlag,
                    isDigital,
                    name: searchName,
                    limit: 15,
                }).$promise;

                resolve(mediaFormats.data);
            });
        }

        configureChannelMapping(config:any, mediaFormatId:number, editable:boolean) {
            let vm = this;

            // need to look up the selected media format because the object may not reflect a new id chosen by the user.
            let mediaFormat = vm.mediaFormats.filter(function (format:any) {
                return format.id == mediaFormatId;
            });

            if (mediaFormat.length == 1 && editable) {
                let selectedFormat = mediaFormat[0];

                vm.$mdDialog
                    .show({
                        controller: ConfigureChannelMappingDialogController,
                        controllerAs: 'vm',
                        template: require('../defaultmediaprefersdetail/dialogs/configureChannelMappingDialog-template.html'),
                        parent: angular.element(document.body),
                        clickOutsideToClose: true,
                        fullscreen: true, // For small screens only
                        locals: {
                            config: config,
                            format: selectedFormat,
                        },
                    })
                    .then(
                        function submitted(saved:any) {
                            if (saved) {
                                vm.$scope.destinationDetailForm.$setDirty();
                            }
                        },
                        function cancelled() {
                            // Do nothing, the user cancelled
                        }
                    );
            }
        }

        dirtyProxySelects() {
            let vm = this;

            if (vm.mediaPrefObj.proxyFlag && vm.mediaPrefObj.sdProxyFormatId === '') {
                vm.mediaPrefObj.sdProxyFormatId = undefined;
            }

            if (vm.mediaPrefObj.proxyFlag && vm.mediaPrefObj.hdProxyFormatId === '') {
                vm.mediaPrefObj.hdProxyFormatId = undefined;
            }
        }

        /* IMPLEMENTATION : PRIVATE */
        // All private methods should start with '_' in order to distinguish them
        reset() {
            this.$scope.destinationDetailForm.$setPristine();
            if (this.$stateParams.id == 'new') {
                this._setDefaultMediaPrefs();
                this.$scope.destinationDetailForm.$setUntouched();
                this.akaModified = '';
            } else {
                this.destination = angular.copy(this.originalDestination);
                this.mediaPrefObj = this.destination.mediaPrefsOverrideFlag
                    ? this.destination
                    : this.destination.ReceiverAccount;
                this.akaModified = '';
                if(this.destination.mediaPrefsOverrideFlag && (this.destination.youtubeFlag || this.destination.flashtalkingFlag || this.destination.innovidFlag) && this.mediaPrefObj.vastVersion) {
                    this.$scope.destinationDetailForm.$setDirty();
                }
                if (this.destination.aka) {
                    this.akaModified = angular.copy(
                        this.destination.aka.replace(/\|/g, ',').replace(/^,|,$/g, '')
                    );
                }
            }
        }

        async save(addAnother:boolean) {
            let vm = this;

            // I guess this should be false..?
            vm.destination.archivedFlag = false;

            if (vm.promoDestTypeIds.indexOf(vm.destination.destinationTypeId) < 0) {
                vm.destination.operationsDestination = null;
            }

            // We don't want to update these, so we need to NOT send them
            delete vm.destination.DestinationType;
            delete vm.destination.ReceiverAccount;

            if (vm.akaModified) {
                // If there is an AKA, modify to fit our formatting in the DB
                vm.destination.aka = '|' + vm.akaModified.replace(/\,/g, '|') + '|';
            } else {
                // If there is no AKA, don't ignore that 
                vm.destination.aka = '';
            }

            let newVideoFormatIdCreated = await vm._setNewVideoFrmtProflId();

            if (!newVideoFormatIdCreated) {
                return;
            }
            
            if (vm.destination.hdMediaFormatId == '') {
                vm.destination.hdMediaFormatId = null;
            }

            if (vm.destination.sdMediaFormatId == '') {
                vm.destination.sdMediaFormatId = null;
            }

            if (vm.destination.spotMetadataXformId == '') {
                vm.destination.spotMetadataXformId = null;
            }

            if (vm.destination.hdProxyFormatId == '') {
                vm.destination.hdProxyFormatId = null;
            }

            if (vm.destination.sdProxyFormatId == '') {
                vm.destination.sdProxyFormatId = null;
            }

            // Apply media format object data to the destination before saving
            if (vm.mediaPrefObj.sdMediaFormat && vm.mediaPrefObj.sdMediaFormat.id && vm.destination.mediaPrefsOverrideFlag) {
                vm.destination.sdMediaFormatId = vm.mediaPrefObj.sdMediaFormat.id;
            } else if (!vm.mediaPrefObj.sdMediaFormat && vm.destination.mediaPrefsOverrideFlag) {
                vm.destination.sdMediaFormatId = null;
            }

            if (vm.mediaPrefObj.sdProxyFormat && vm.mediaPrefObj.sdProxyFormat.id && vm.destination.mediaPrefsOverrideFlag) {
                vm.destination.sdProxyFormatId = vm.mediaPrefObj.sdProxyFormat.id;
            } else if (!vm.mediaPrefObj.sdProxyFormat && vm.destination.mediaPrefsOverrideFlag) {
                vm.destination.sdProxyFormatId = null;
            }

            if (vm.mediaPrefObj.hdMediaFormat && vm.mediaPrefObj.hdMediaFormat.id && vm.destination.mediaPrefsOverrideFlag) {
                vm.destination.hdMediaFormatId = vm.mediaPrefObj.hdMediaFormat.id;
            } else if (!vm.mediaPrefObj.hdMediaFormat && vm.destination.mediaPrefsOverrideFlag) {
                vm.destination.hdMediaFormatId = null;
            }

            if (vm.mediaPrefObj.hdProxyFormat && vm.mediaPrefObj.hdProxyFormat.id && vm.destination.mediaPrefsOverrideFlag) {
                vm.destination.hdProxyFormatId = vm.mediaPrefObj.hdProxyFormat.id;
            } else if (!vm.mediaPrefObj.hdProxyFormat && vm.destination.mediaPrefsOverrideFlag) {
                vm.destination.hdProxyFormatId = null;
            }

            // Do the actual saving, I leave these calls full-sized (not await'd) so that they can be error handled easiest.
            if (vm.$stateParams.id == 'new') {
                vm.destination.preproductionFlag = false;
                vm.DestinationResourceFactory.create(
                    {},
                    vm.destination,
                    function (destination:any) {
                        vm.NotificationService.showNotificationToast(
                            'Successfully created destination'
                        );
                        vm.$scope.destinationDetailForm.$setPristine();
                        vm.destination = angular.copy(
                            destination.data
                        );
                        vm.originalDestination = angular.copy(
                            destination.data
                        );
                        if (addAnother) {
                            vm.$state.reload();
                        } else {
                            vm.$state.go('destinationDetails', {
                                id: destination.data.id,
                            });
                        }
                    },
                    function failure(err:any) {
                        vm.NotificationService.showNotificationToast(
                            'Unable to save the destination with the given information',
                            err.data
                        );
                    }
                );
            } else {
                vm.DestinationResourceFactory.save(
                    { id: vm.destination.id },
                    vm.destination,
                    function (destination:any) {
                        vm.NotificationService.showNotificationToast(
                            'Successfully updated destination'
                        );
                        vm.destination = angular.copy(
                            destination.data
                        );

                        // Re-sync the mediaPrefObj (that I hate)
                        vm.resetMediaPrefObj(false);
                        vm._setMediaFormatTexts();

                        vm.originalDestination = angular.copy(
                            destination.data
                        );
                        vm.$scope.destinationDetailForm.$setPristine();
                        if (addAnother) {
                            vm.$state.go('destinationDetails', {
                                id: 'new',
                            });
                        }
                    },
                    function failure(err:any) {
                        vm.NotificationService.showNotificationToast(
                            'Unable to save the destination with the given information',
                            err.data
                        );
                    }
                );
            }
        }

        disableSave() {
            let vm = this;
            if (
                vm.$scope.destinationDetailForm !== undefined &&
                (!vm.$scope.destinationDetailForm.$dirty || !vm.$scope.destinationDetailForm.$valid)
            ) {
                return true;
            }
            return false;
        }

        disableReset() {
            let vm = this;
            if (
                vm.$scope.destinationDetailForm !== undefined &&
                !vm.$scope.destinationDetailForm.$dirty
            ) {
                return true;
            }
            return false;
        }

        _setDefaultMediaPrefs() {
            let vm = this;
            vm.destination = {};
            vm.destination.active = true;
            vm.destination.enabled = true;
            vm.destination.mediaPrefsOverrideFlag = false;
            vm.destination.name = '';
            vm.destination.ReceiverAccount = angular.copy(vm.receiver);
            vm.destination.vastVersion = null;
            vm.destination.ReceiverAccount.vastVersion = null;
            vm._setDefaultDestinationMediaPrefs();
            vm.resetMediaPrefObj(false);
        }

        _setDefaultDestinationMediaPrefs() {
            let vm = this;
            vm.destination.xmlFilenamePattern = angular.copy(vm.receiver.xmlFilenamePattern);
            vm.destination.filenamePattern = angular.copy(vm.receiver.filenamePattern);
            vm.destination.vastVersion = angular.copy(vm.receiver.vastVersion);
            vm.destination.deliverMetadataAfterMediaFlag = vm.receiver.deliverMetadataAfterMediaFlag
                ? angular.copy(vm.receiver.deliverMetadataAfterMediaFlag)
                : false;
            vm.destination.proxyFlag = vm.receiver.proxyFlag
                ? angular.copy(vm.receiver.proxyFlag)
                : false;
            vm.destination.parkDeliveryFlag = vm.receiver.parkDeliveryFlag
                ? angular.copy(vm.receiver.parkDeliveryFlag)
                : false;
            vm.destination.autoAcceptPromo = vm.receiver.rcvrAutoAcceptPromo
                ? angular.copy(vm.receiver.rcvrAutoAcceptPromo)
                : false;
            vm.destination.separateSlateFlag = vm.receiver.separateSlateFlag
                ? angular.copy(vm.receiver.separateSlateFlag)
                : false;
            vm.destination.exactLengthFlag = vm.receiver.exactLengthFlag
                ? angular.copy(vm.receiver.exactLengthFlag)
                : false;
            vm.destination.exactLengthCorrectionValue = angular.copy(
                vm.receiver.exactLengthCorrectionValue
            );
            vm.destination.spotMetadataXformId = angular.copy(vm.receiver.spotMetadataXformId);
            vm.destination.sdMediaFormatId = angular.copy(vm.receiver.sdMediaFormatId);
            vm.destination.sdProxyFormatId = angular.copy(vm.receiver.rcvrSdProxyFormatId);
            vm.destination.hdMediaFormatId = angular.copy(vm.receiver.hdMediaFormatId);
            vm.destination.hdProxyFormatId = angular.copy(vm.receiver.rcvrHdProxyFormatId);
            vm.destination.sdCompanionRequiredFlag = vm.receiver.sdCompanionRequiredFlag
                ? angular.copy(vm.receiver.sdCompanionRequiredFlag)
                : false;
            vm.destination.sdVidFrmtProflId = angular.copy(vm.receiver.sdVidFrmtProflId);

            vm.receiver.SDVideoFormatProfile = vm.receiver.SDVideoFormatProfile
                ? vm.receiver.SDVideoFormatProfile
                : {};
            vm.destination.SDVideoFormatProfile = angular.copy(vm.receiver.SDVideoFormatProfile);
            vm.destination.sdProxyFrmtProflId = angular.copy(vm.receiver.sdProxyFrmtProflId);

            vm.receiver.SDProxyFormatProfile = vm.receiver.SDProxyFormatProfile
                ? vm.receiver.SDProxyFormatProfile
                : {};
            vm.destination.SDProxyFormatProfile = angular.copy(vm.receiver.SDProxyFormatProfile);
            vm.destination.hdVidFrmtProflId = angular.copy(vm.receiver.hdVidFrmtProflId);

            vm.receiver.HDVideoFormatProfile = vm.receiver.HDVideoFormatProfile
                ? vm.receiver.HDVideoFormatProfile
                : {};
            vm.destination.HDVideoFormatProfile = angular.copy(vm.receiver.HDVideoFormatProfile);

            vm.receiver.HDProxyFormatProfile = vm.receiver.HDProxyFormatProfile
                ? vm.receiver.HDProxyFormatProfile
                : {};
            vm.destination.HDProxyFormatProfile = angular.copy(vm.receiver.HDProxyFormatProfile);
            vm.destination.hdProxyFrmtProflId = angular.copy(vm.receiver.hdProxyFrmtProflId);
        }

        _setNewVideoFrmtProflId() {
            let vm = this;

            let deferred = vm.$q.defer();

            vm.$q
                .all([
                    // Create New SD video format
                    vm._createNewVideoFrmtProflId(
                        vm.destination.sdVidFrmtProflId,
                        vm.receiver.sdVidFrmtProflId
                    ),
                    // Create New SD proxy video format
                    vm._createNewVideoFrmtProflId(
                        vm.destination.sdProxyFrmtProflId,
                        vm.receiver.sdProxyFrmtProflId
                    ),
                    // Create New HD video format
                    vm._createNewVideoFrmtProflId(
                        vm.destination.hdVidFrmtProflId,
                        vm.receiver.hdVidFrmtProflId
                    ),
                    // Create New HD proxy video format
                    vm._createNewVideoFrmtProflId(
                        vm.destination.hdProxyFrmtProflId,
                        vm.receiver.hdProxyFrmtProflId
                    ),
                ])
                .then(function (dataResults:any) {
                    if (dataResults[0]) {
                        vm.destination.sdVidFrmtProflId = dataResults[0].id;
                        vm.destination.SDVideoFormatProfile.id = dataResults[0].id;
                    }

                    if (dataResults[1]) {
                        vm.destination.sdProxyFrmtProflId = dataResults[1].id;
                        vm.destination.SDProxyFormatProfile.id = dataResults[1].id;
                    }

                    if (dataResults[2]) {
                        vm.destination.hdVidFrmtProflId = dataResults[2].id;
                        vm.destination.HDVideoFormatProfile.id = dataResults[2].id;
                    }

                    if (dataResults[3]) {
                        vm.destination.hdProxyFrmtProflId = dataResults[3].id;
                        vm.destination.HDProxyFormatProfile.id = dataResults[3].id;
                    }
                    deferred.resolve(true);
                });

            return deferred.promise;
        }

        _createNewVideoFrmtProflId(destVideoFormatId:number, rcvrVideoFormatId:number) {
            let vm = this;
            let deferred = vm.$q.defer();
            if (destVideoFormatId == rcvrVideoFormatId) {
                let param:any = {};
                param.accountType = 'RECEIVER';
                vm.VideoFormatProfileResource.create(
                    {},
                    param,
                    function (response:any) {
                        deferred.resolve(response.data);
                    },
                    function failure(err:any) {
                        vm.NotificationService.showNotificationToast(
                            'Unable to create new video profile',
                            err.data
                        );
                    }
                );
            } else {
                deferred.resolve(false);
            }
            return deferred.promise;
        }

        _setMediaFormatTexts() {
            let vm = this;

            // If for some reason the mediaPrefs aren't populated, just get out of here
            if (!vm.mediaPrefObj) {
                return;
            }

            // Populate media format dropdowns
            if (vm.mediaPrefObj.sdMediaFormatId) {
                vm.MediaFormatResourceFactory.get({ id: vm.mediaPrefObj.sdMediaFormatId }, (format:any) => {
                    vm.searchSdFormat = format.name;
                    vm.mediaPrefObj.sdMediaFormat = format;
                    vm.destination.sdMediaFormatId = vm.mediaPrefObj.sdMediaFormatId;
                });
            }
            if (vm.mediaPrefObj.sdProxyFormatId) {
                vm.MediaFormatResourceFactory.get({ id: vm.mediaPrefObj.sdProxyFormatId }, (format:any) => {
                    vm.searchSdProxyFormat = format.name;
                    vm.mediaPrefObj.sdProxyFormat = format;
                    vm.destination.sdProxyFormatId = vm.mediaPrefObj.sdProxyFormatId;
                });
            }

            if (vm.mediaPrefObj.hdMediaFormatId) {
                vm.MediaFormatResourceFactory.get({ id: vm.mediaPrefObj.hdMediaFormatId }, (format:any) => {
                    vm.searchHdFormat = format.name;
                    vm.mediaPrefObj.hdMediaFormat = format;
                    vm.destination.hdMediaFormatId = vm.mediaPrefObj.hdMediaFormatId;
                });
            }
            if (vm.mediaPrefObj.hdProxyFormatId) {
                vm.MediaFormatResourceFactory.get({ id: vm.mediaPrefObj.hdProxyFormatId }, (format:any) => {
                    vm.searchHdProxyFormat = format.name;
                    vm.mediaPrefObj.hdProxyFormat = format;
                    vm.destination.hdProxyFormatId = vm.mediaPrefObj.hdProxyFormatId;
                });
            }
        }

        /* INITIALIZATION */
        $onInit() {
            let vm = this;
            if (vm.$scope.home) {
                vm.$scope.home.showTgl = false;
            }

            let securityAccount = vm.loginService.getSessionData();
            switch (securityAccount.accountType) {
                case 'RECEIVER':
                    vm.$q
                        .all([
                            vm.EnumService.getEnum('Spot', 'spotMetadataTransform'),
                            vm.EnumService.getEnum('AdDeliverySecurityAccount', 'filenamePattern'),
                            vm.EnumService.getEnum(
                                'AdDeliverySecurityAccount',
                                'xmlFilenamePattern'
                            ),
                            vm.EnumService.getEnum('ReceiverVideoFormatProfile', 'maxPeak'),
                            vm.EnumService.getEnum('ReceiverVideoFormatProfile', 'targetLoudness'),
                            vm.MediaFormatResourceFactory.query().$promise,
                            vm.ReceiverResourceFactory.get({
                                id: vm.sessionData.accountId,
                            }).$promise,
                        ])
                        .then(function (dataResults:any) {
                            vm.spotMetadataTransformList = dataResults[0];
                            vm.fileNamePatterns = dataResults[1];
                            vm.xmlFilenamePatterns = dataResults[2];
                            vm.maxPeakPatterns = dataResults[3];
                            vm.targetLoudnessPatterns = dataResults[4];
                            vm.mediaFormats = dataResults[5].data;
                            vm.receiver = dataResults[6];

                            if (vm.$stateParams.id == 'new') {
                                vm._setDefaultMediaPrefs();
                                vm.originalDestination = angular.copy(vm.destination);
                            } else {
                                vm.DestinationResourceFactory.get(
                                    { id: vm.$stateParams.id },
                                    (destination:any) => {
                                        vm.destination = destination;

                                        if (vm.destination.mediaPrefsOverrideFlag == null) {
                                            vm.destination.mediaPrefsOverrideFlag = false;
                                        }

                                        if (vm.destination.aka) {
                                            vm.akaModified = vm.destination.aka
                                                .replace(/\|/g, ',')
                                                .replace(/^,|,$/g, '');
                                        }
                                        vm.originalDestination = angular.copy(destination);
                                        vm.mediaPrefObj = vm.destination.mediaPrefsOverrideFlag
                                            ? vm.destination
                                            : vm.destination.ReceiverAccount;
                                        if(vm.destination.mediaPrefsOverrideFlag && (vm.destination.youtubeFlag || vm.destination.flashtalkingFlag || vm.destination.innovidFlag)) {
                                            if(vm.mediaPrefObj.vastVersion) {
                                                vm.mediaPrefObj.vastVersion = null; 
                                                vm.$scope.destinationDetailForm.$setDirty();    
                                            }
                                        }
                                        vm._setMediaFormatTexts();

                                        if (
                                            vm.destination.DestinationType.distributionType ===
                                            'PROMO'
                                        ) {
                                            vm.DestinationResourceFactory.getAll(
                                                {
                                                    distributionType: 'TV',
                                                    accountId: vm.loginService.getSessionData()
                                                        .accountId,
                                                },
                                                function success(destinations:any) {
                                                    vm.operationsDestinations =
                                                        destinations.data.rows;
                                                    vm.DestinationResourceFactory.getOperationsDestination(
                                                        {
                                                            id: vm.$stateParams.id,
                                                        },
                                                        {},
                                                        (allDestinations:any) => {
                                                            vm.destination.operationsDestination = allDestinations
                                                                ? allDestinations.data.opsDest.id
                                                                : null;
                                                        }
                                                    );
                                                },
                                                function failure(err:any) {
                                                    console.log(err);
                                                }
                                            );
                                        }
                                    }
                                );
                            }
                        });
                    break;
                case 'PROVIDER':
                    let sidebar:any = document.querySelector('.sidebar');
                    angular.element(sidebar).addClass('hide');

                    vm.DestinationResourceFactory.get({ id: vm.$stateParams.id }, (destination:any) => {
                        vm.destination = destination;
                        vm.destination.vastVersion = !destination.mediaPrefsOverrideFlag
                            ? vm.destination.ReceiverAccount.vastVersion
                            : vm.destination.vastVersion;
                        if (vm.destination.aka) {
                            vm.akaModified = vm.destination.aka
                                .replace(/\|/g, ',')
                                .replace(/^,|,$/g, '');
                        }
                        vm.originalDestination = angular.copy(destination);

                        //get destination status
                        vm.DestinationResourceFactory.getStatus(
                            {
                                id: vm.$stateParams.id,
                                distributionType: vm.destination.distributionType
                                    ? vm.destination.distributionType
                                    : vm.destination.distribution_type,
                                receiverId: vm.destination.ReceiverAccount.id,
                            },
                            function (status:any) {
                                vm.$timeout(function () {
                                    vm.destinationStatuses[vm.$stateParams.id] =
                                        status.data == null || status.data == undefined
                                            ? 'false'
                                            : status.data.toString();
                                });
                            },
                            function failure() {}
                        );

                        //get destination capabilities
                        vm.DestinationResourceFactory.getCapabilities(
                            { id: vm.$stateParams.id },
                            function (capabilities:any) {
                                vm.$timeout(function () {
                                    vm.destinationCapabilities[vm.$stateParams.id] =
                                        capabilities && capabilities.data ? capabilities.data : {};
                                });
                            },
                            function failure() {
                                vm.destinationCapabilities[vm.$stateParams.id] = {};
                            }
                        );
                    });
                    break;
            }

            // This could one day grow too large, we should make this a typeahead too
            vm.MarketResourceFactory.getAll(
                {},
                function (markets:any) {
                    vm.markets = markets.data;
                },
                function () {
                    vm.markets = [];
                }
            );

            vm.DestinationTypeResourceFactory.getAll(
                {},
                (types:any) => {
                    vm.destinationTypes = types.data;
                    for (let i = 0; i < vm.destinationTypes.length; i++) {
                        if (vm.destinationTypes[i].distributionType === 'PROMO') {
                            vm.promoDestTypeIds.push(vm.destinationTypes[i].id);
                        }
                        if (vm.destinationTypes[i].distributionType === 'RADIO') {
                            vm.radioDestTypeIds.push(vm.destinationTypes[i].id);
                        }
                        // NOTE: COM-16752 created this field in Admin, and was put here on accident as well.
                        // It is disabled until a time when we decide we would like to view/modify this in Ripley as well
                        if (vm.destinationTypes[i].distributionType === 'DIGITAL') {
                            vm.digitalDestTypeIds.push(vm.destinationTypes[i].id);
                        }
                    }
                },
                () => {
                    vm.destinationTypes = [];
                }
            );

            vm.$timeout(function () {
                vm.$rootScope.destinationDetailForm = vm.$scope.destinationDetailForm;
            });
        }
    },
    controllerAs: 'vm',
};
